<template>
  <div class="TestKitDetail">
    <div class="TestKitDetail-form">
      <el-form
        ref="form"
        label-width="160px"
        :hide-required-asterisk="true"
        :rules="rules"
        :model="form"
        @submit.native.prevent
        size="small"
      >
        <div class="TestKitDetail-form-content">
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="form.name"
              clearable
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCardNo">
            <el-input
              v-model="form.idCardNo"
              @change="idCardNoChange"
              clearable
              placeholder="请输入身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="form.gender">
              <el-radio label="0">女</el-radio>
              <el-radio label="1">男</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-input
              v-model="form.age"
              clearable
              placeholder="请输入年龄"
            ></el-input>
          </el-form-item>
          <el-form-item label="身高" prop="height">
            <el-input v-model="form.height" clearable placeholder="请输入身高">
              <template #append>
                <span>厘米</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="体重" prop="weight">
            <el-input v-model="form.weight" clearable placeholder="请输入体重">
              <template #append>
                <span>公斤</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="收缩压（选填）"
            prop="systolicPressure"
            v-if="sort[routerName] == 'taxi'"
          >
            <el-input
              v-model="form.systolicPressure"
              clearable
              placeholder="请输入收缩压"
            >
              <template #append>
                <span>mmHg</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="舒张压（选填）"
            prop="diastolicPressure"
            v-if="sort[routerName] == 'taxi'"
          >
            <el-input
              v-model="form.diastolicPressure"
              clearable
              placeholder="请输入舒张压"
            >
              <template #append>
                <span>mmHg</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="民族"
            prop="nation"
            v-if="sort[routerName] == 'taxi'"
          >
            <el-radio-group v-model="form.nation">
              <el-radio label="0">汉</el-radio>
              <el-radio label="1">回</el-radio>
              <el-radio label="2">满</el-radio>
              <el-radio label="3">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="出生日期"
            prop="birthday"
            v-if="sort[routerName] == 'taxi'"
          >
            <el-date-picker
              v-model="form.birthday"
              clearable
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请输入出生日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="教育程度" prop="educationLevel">
            <el-radio-group v-model="form.educationLevel">
              <el-radio
                v-for="item in $store.state.educationLevelList"
                :key="item.dictKey"
                :label="item.dictKey"
                >{{ item.dictValue }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="婚姻状况" prop="maritalStatus">
            <el-radio-group v-model="form.maritalStatus">
              <el-radio
                v-for="item in $store.state.maritalStatusList"
                :key="item.dictKey"
                :label="item.dictKey"
                >{{ item.dictValue }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="职业"
            prop="occupation"
            v-if="sort[routerName] != 'taxi'"
          >
            <el-radio-group v-model="form.occupation">
              <el-radio
                v-for="item in $store.state.occupation"
                :key="item.dictKey"
                :label="item.dictKey"
                >{{ item.dictValue }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="医保类型" prop="medicalInsuranceType">
            <el-checkbox-group v-model="form.medicalInsuranceType">
              <el-checkbox
                v-for="item in $store.state.medicalInsuranceTypeList"
                :key="item.dictKey"
                :label="item.dictKey"
                >{{ item.dictValue }}</el-checkbox
              >
            </el-checkbox-group>
            <!--            <el-radio-group v-model="form.medicalInsuranceType">-->
            <!--              <el-radio label="0">城镇职工</el-radio>-->
            <!--              <el-radio label="1">城镇居民</el-radio>-->
            <!--              <el-radio label="2">公费医疗</el-radio>-->
            <!--              <el-radio label="3">商业保险</el-radio>-->
            <!--              <el-radio label="4">自费</el-radio>-->
            <!--              <el-radio label="5">其他</el-radio>-->
            <!--            </el-radio-group>-->
          </el-form-item>
          <el-form-item
            label="地址"
            prop="residentialAddress"
            v-if="sort[routerName] == 'taxi'"
          >
            <el-select
              v-model="form.residentialAddress"
              clearable
              placeholder="请选择地址"
            >
              <el-option
                v-for="item in nresidentialAddressList"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="住址"
            prop="detailAddress"
            v-if="sort[routerName] != 'taxi'"
          >
            <el-input
              v-model="form.detailAddress"
              clearable
              placeholder="请输入住址"
            ></el-input>
          </el-form-item>
          <el-form-item label="受检人手机号" prop="phoneNoOne">
            <el-input
              v-model="form.phoneNoOne"
              clearable
              placeholder="请输入受检人手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他联系方式（选填）" prop="phoneNoTwo">
            <el-input
              v-model="form.phoneNoTwo"
              clearable
              placeholder="请输入其他联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="试剂盒编号" prop="reagentNo">
            <el-input
              v-model="form.reagentNo"
              clearable
              placeholder="请输入试剂盒编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="填表日期" prop="fillFormDate">
            <el-date-picker
              v-model="form.fillFormDate"
              clearable
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请输入填表日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发放人（选填）" prop="inviter">
            <el-input
              v-model="form.inviter"
              clearable
              placeholder="请输入发放人"
            ></el-input>
          </el-form-item>
          <el-form-item label="领取日期（选填）" prop="receiveDate">
            <el-date-picker
              v-model="form.receiveDate"
              clearable
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请输入领取日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="回收日期（选填）" prop="recoverDate">
            <el-date-picker
              v-model="form.recoverDate"
              clearable
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请输入回收日期"
            >
            </el-date-picker>
          </el-form-item>

          <template>
            <div
              class="TestKitDetail-form-content-list"
              v-for="(item, index) in TestKitList"
              :key="index"
            >
              <div class="TestKitDetail-form-content-list-title">
                {{ item.category }}
              </div>
              <template>
                <div v-for="(items, indexs) in item.questionList" :key="indexs">
                  <template v-if="items.isShow == '1'">
                    <el-form-item
                      v-if="items.type == '0'"
                      :label="items.title"
                      :prop="'questions.value' + items.questionId"
                      :rules="{
                        required: true,
                        message: '该项为必填项',
                        trigger: 'change',
                      }"
                    >
                      <el-radio-group
                        v-model="form.questions['value' + items.questionId]"
                        @change="questionChange($event, items)"
                      >
                        <el-radio
                          v-for="itemss in items.answerList"
                          :key="itemss.answerId"
                          :label="itemss.answerId"
                          >{{ itemss.answerContent }}</el-radio
                        >
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="items.type == '2'" :label="items.title">
                      <el-form-item
                        :label="itemss.title"
                        :prop="'questions.value' + itemss.questionId"
                        :rules="{
                          required: true,
                          message: '该项为必填项',
                          trigger: 'blur',
                        }"
                        v-for="(
                          itemss, indexss
                        ) in items.childrenQuestionVOList"
                        :key="indexss"
                      >
                        <!-- :rules="getRules(itemss)" -->
                        <el-input
                          v-model="form.questions['value' + itemss.questionId]"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-form-item>
                  </template>
                </div>
              </template>
            </div>
          </template>

          <el-form-item
            label="上传基因报告"
            prop="questionnaireResult"
            v-if="reagentId && routerName != 'FitScreening'"
          >
            <el-upload
              ref="upload"
              :class="uploadDisabledPDf"
              list-type="picture-card"
              accept=".pdf"
              :data="dataPDF($route.query.reagentId)"
              :action="action"
              :file-list="fileListPDf"
              :headers="headers"
              :limit="1"
              :on-change="handleChange"
              :on-success="successUpload"
              :before-upload="beforeUploadPDF"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">只能上传pdf文件</div>
              <div class="upload_image" slot="file" slot-scope="{ file }">
                <el-image
                  v-if="file.url"
                  :src="file.url"
                  :preview-src-list="[file.url]"
                >
                  <div
                    slot="error"
                    class="image-slot"
                    style="
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <i class="el-icon-loading" style="font-size: 18px"></i>
                    <span style="margin-left: 4px">加载中...</span>
                  </div>
                </el-image>

                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file, (showViewer = true))"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="问卷风险结果（选填）"
            prop="questionnaireResult"
            v-if="reagentId"
          >
            <el-select
              v-model="form.questionnaireResult"
              clearable
              placeholder="请选择问卷风险结果"
            >
              <el-option
                v-for="item in questionnaireResult_list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="检测结果（选填）"
            prop="detectResult"
            v-if="reagentId"
          >
            <el-select
              v-model="form.detectResult"
              clearable
              placeholder="请选择检测结果"
            >
              <el-option
                v-for="item in detectResult_list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="客服呼叫备注（选填）"
            prop="serviceCallRecord"
            v-if="reagentId"
          >
            <el-input
              type="textarea"
              v-model="form.serviceCallRecord"
              clearable
              placeholder="请输入客服呼叫备注"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item>
          <div class="TestKitDetail-form-button">
            <el-button
              native-type="submit"
              @click="onsubmit(form)"
              type="primary"
              >保存</el-button
            >
            <el-button @click="cancel">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imageViewer"
    />
  </div>
</template>

<script>
import idCardNo from '../../utils/idCardNo'

export default {
  name: 'ColorectalScreening',
  data() {
    let routerName = this.$route.name.split('Detail')[0]
    routerName = routerName.split('Add')[0]
    var validatorIdCard = (rule, value, callback) => {
      this.$refs['form'].validateField('age')
      if (idCardNo.idCardNo.checkIdCardNo(value)) {
        callback()
      } else {
        callback(new Error('身份证号不正确'))
      }
    }
    return {
      form: {
        name: null, // 姓名
        idCardNo: null, // 身份证号
        age: null, // 年龄
        gender: null, // 性别
        height: null, // 身高
        weight: null, // 体重
        diastolicPressure: null, // 舒张压
        systolicPressure: null, // 收缩压
        nation: null, // 民族
        birthday: null, // 出生日期
        educationLevel: null, // 教育成都
        maritalStatus: null, // 婚姻状态
        medicalInsuranceType: [], // 医保类型
        residentialAddress: null, // 地址
        phoneNoOne: null, // 受检人手机号
        phoneNoTwo: null, // 其他联系方式
        reagentNo: null, // 试剂盒编号
        questionnaireResult: null, // 问卷风险结果
        detectResult: null, // 检测结果
        fillFormDate: null, // 填表日期
        receiveDate: null, // 领取日期
        recoverDate: null, // 回收日期
        serviceCallRecord: null, // 客服呼叫备注
        occupation: null, // 职业
        detailAddress: null, // 输入住址
        inviter: null, // 发放人
        reportPath: null, // 上传基因报告
        reportUrl: null,
        delReport: 0, // 是否删除基因报告
        questions: {},
      },
      reagentId: this.$route.query.reagentId
        ? this.$route.query.reagentId
        : null,
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        idCardNo: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: validatorIdCard, trigger: 'blur' },
        ],
        age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        height: [{ required: true, message: '请输入身高', trigger: 'blur' }],
        weight: [{ required: true, message: '请输入体重', trigger: 'blur' }],
        // diastolicPressure: [
        //     { required: true, message: '请输入舒张压', trigger: 'blur' },
        // ],
        // systolicPressure: [
        //     { required: true, message: '请输入收缩压', trigger: 'blur' },
        // ],
        nation: [{ required: true, message: '请选择民族', trigger: 'change' }],
        fillFormDate: [
          { required: true, message: '请选择填表日期', trigger: 'change' },
        ],
        birthday: [
          { required: true, message: '请选择出生日期', trigger: 'change' },
        ],
        educationLevel: [
          { required: true, message: '请选择教育程度', trigger: 'change' },
        ],
        maritalStatus: [
          { required: true, message: '请选择婚姻状态', trigger: 'change' },
        ],
        medicalInsuranceType: [
          { required: true, message: '请选择医保类型', trigger: 'change' },
        ],
        residentialAddress: [
          { required: true, message: '请选择地址', trigger: 'change' },
        ],
        phoneNoOne: [
          { required: true, message: '请输入受检人手机号', trigger: 'blur' },
          {
            pattern:
              /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/,
            message: '受检人手机号不正确',
            trigger: 'blur',
          },
        ],
        phoneNoTwo: [
          // { required: true, message: '请输入其他联系方式', trigger: 'blur' },
          {
            pattern:
              /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/,
            message: '其他联系方式不正确',
            trigger: 'blur',
          },
        ],
        reagentNo: [
          { required: true, message: '请输入试剂盒编号', trigger: 'blur' },
          routerName === 'FitScreening'
            ? {
                min: 13,
                max: 14,
                message: '长度为 13 到 14 个字符',
                trigger: 'blur',
              }
            : {
                min: 6,
                max: 10,
                message: '长度为 6 到 10 个字符',
                trigger: 'blur',
              },
        ],
        occupation: [
          { required: true, message: '请选择职位', trigger: 'change' },
        ],
        detailAddress: [
          { required: true, message: '请输入住址', trigger: 'blur' },
        ],
      },
      rules_: {},
      questionnaireResult_list: [
        {
          label: '低级',
          value: '低级',
        },
        {
          label: '中级',
          value: '中级',
        },
        {
          label: '高级',
          value: '高级',
        },
      ],
      detectResult_list: [
        {
          label: '阴性',
          value: '阴性',
        },
        {
          label: '阳性',
          value: '阳性',
        },
        {
          label: '不合格',
          value: '不合格',
        },
      ],
      TestKitList: [],
      nresidentialAddressList: [],
      uploadDisabledPDf: null,
      action: process.env.VUE_APP_APIURL + 'tempReagent/normalDetail/upload', // 上传基因报告
      fileListPDf: [],
      headers: {
        authorization: this.$store.state.token,
      },
      showViewer: false,
      imageViewer: [],
      infoApi: {
        ColorectalScreening: 'tempReagent/info',
        TestKitScreening: 'tempReagent/info',
        FitScreening: 'tempReagent/fitInfo',
      },
      questionApi: {
        ColorectalScreening: 'tempReagent/question',
        TestKitScreening: 'tempReagent/question',
        FitScreening: 'tempReagent/fitQuestion',
      },
      addApi: {
        ColorectalScreening: 'tempReagent/addNormal',
        TestKitScreening: 'tempReagent/addNormal',
        FitScreening: 'tempReagent/addFit',
      },
      sort: {
        ColorectalScreening: 'normal',
        TestKitScreening: 'shi',
        FitScreening: 'fit',
      },
    }
  },
  computed: {
    routerName() {
      let routerName = this.$route.name.split('Detail')[0]
      routerName = routerName.split('Add')[0]
      // console.log(routerName)
      return routerName
    },
  },
  created() {
    this.address()
    this.$store.dispatch('setOccupation', 'normal')
    this.$store.dispatch('setEducationLevelList', 'normal')
    this.$store.dispatch('setMaritalStatusList', 'normal')
    this.$store.dispatch('setMedicalInsuranceTypeList', 'normal')

    let obj = {}
    obj['activityType'] = this.sort[this.routerName]
    obj['activityType'] == 'shi' ? (obj['activityType'] = 'normal') : null
    if (this.$route.query.reagentId) {
      obj['reagentId'] = this.$route.query.reagentId
      this.get_info({ reagentId: this.$route.query.reagentId })
    }

    this.get_question(obj)
  },
  methods: {
    dataPDF(e) {
      let obj = {
        reagentId: e,
      }
      return obj
    },
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.uploadDisabledPDf = 'disabled'
      }
    },
    successUpload(res, file) {
      this.form.reportPath = res.data.path
      this.form.reportUrl = res.data.url
      file['path'] = res.data.path
      file.url = res.data.url
    },
    beforeUploadPDF(file) {
      let extension = file.name.split('.')[1]
      let extensionList = ['pdf', 'PDF']

      if (extensionList.indexOf(extension) < 0) {
        this.$message.warning('只能上传pdf文件')
        this.uploadDisabledPDf = null
        return false
      }
    },
    closeViewer() {
      this.showViewer = false
      this.imageViewer = []
    },
    handlePictureCardPreview(e) {
      this.imageViewer.push(e.url)
    },
    handleRemove(file) {
      let num = 0

      this.$refs['upload'].uploadFiles.map((item) => {
        if (item.uid == file.uid) {
          if (file.id) {
            this.form.delReport = 1
            this.$refs['upload'].uploadFiles.splice(num, 1)
          } else {
            this.$post('tempReagent/img/delete', { path: file.path }).then(
              () => {
                this.$refs['upload'].uploadFiles.splice(num, 1)
              }
            )
          }
        }
        num++
      })
      this.uploadDisabledPDf = null
      this.form.reportPath = null
      this.form.reportUrl = null
      this.fileListPDf = []
    },
    address() {
      this.$get('base/reagent/address').then((res) => {
        this.nresidentialAddressList = res.data
      })
    },
    questionChange(e, y) {
      if (e == y.logicAnswerId) {
        y.showId.forEach((item) => {
          this.TestKitList.forEach((items) => {
            items.questionList.forEach((itemss) => {
              if (itemss.questionId == item) {
                itemss.isShow = '1'
              }
            })
          })
        })
      } else {
        y.showId.forEach((item) => {
          this.TestKitList.forEach((items) => {
            items.questionList.forEach((itemss) => {
              if (itemss.questionId == item) {
                itemss.isShow = '0'
                if (itemss.type == '2') {
                  itemss.childrenQuestionVOList.forEach((itemsss) => {
                    itemsss.value = null
                  })
                } else if (itemss.type == '0') {
                  itemss['value' + itemss.questionId] = null
                }
              }
            })
          })
        })
      }
    },
    get_info(y) {
      this.$get(this.infoApi[this.routerName], y).then((res) => {
        console.log(res)

        if (res.data.reportPath) {
          let obj = {}
          obj['id'] = this.reagentId
          obj['path'] = res.data.reportPath
          obj['url'] = res.data.reportUrl
          this.fileListPDf.push(obj)
          this.uploadDisabledPDf = 'disabled'
        }

        this.form = res.data
      })
    },
    get_question(y) {
      let questions = this.form.questions || {}
      this.$get(this.questionApi[this.routerName], y).then((res) => {
        res.data.forEach((item) => {
          item.questionList.forEach((items) => {
            // if (items.value) {
            //   items['value' + items.questionId] = items.value
            // } else {
            //   items['value' + items.questionId] = null
            // }
            if (
              items.childrenQuestionVOList &&
              items.childrenQuestionVOList.length > 0
            ) {
              items.childrenQuestionVOList.map((itemss) => {
                this.$set(
                  questions,
                  'value' + itemss.questionId,
                  itemss.value || null
                )
              })
            } else {
              this.$set(
                questions,
                'value' + items.questionId,
                items.value || null
              )
            }
          })
        })
        this.$set(this.form, 'questions', questions)
        this.$nextTick(() => {
          this.TestKitList = res.data
        })
        console.log(this.form)
      })
    },
    onsubmit(y) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let arr = [],
            questions = { ...y.questions }
          this.TestKitList.forEach((item) => {
            item.questionList.forEach((items) => {
              if (items.isShow === '1') {
                switch (items.type) {
                  case 0: {
                    let obj = {}
                    obj['questionId'] = items.questionId
                    obj['answerId'] = questions['value' + items.questionId]
                    obj['answerStr'] = null
                    arr.push(obj)
                    break
                  }
                  case 2: {
                    items.childrenQuestionVOList.forEach((itemss) => {
                      let obj = {}
                      obj['questionId'] = itemss.questionId
                      obj['answerId'] = null
                      obj['answerStr'] = questions['value' + itemss.questionId]
                      arr.push(obj)
                    })
                    break
                  }
                }
              }
            })
          })
          let obj = {}
          obj['info'] = { ...y, sort: this.sort[this.routerName] }
          obj['questionAndAnswer'] = arr
          obj['reagentId'] = this.reagentId
          delete obj.info.questions
          console.log(obj)

          this.$post(this.addApi[this.routerName], obj).then((res) => {
            console.log(res)

            if (res) {
              this.$message.success(res.message)
              this.$router.go(-1)
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    cancel() {
      this.$router.go(-1)
    },
    idCardNoChange(e) {
      this.$refs['form'].validateField('idCardNo', (valid) => {
        if (!valid) {
          this.form.birthday = idCardNo.idCardNo.getIdCardNoInfo(e).dob
          this.form.age = this.GetAge(e)
          if (e[e.length - 2] % 2 == 0) {
            this.form.gender = '0'
          } else {
            this.form.gender = '1'
          }
        } else {
          this.form.birthday = null
          this.form.age = null
          this.form.gender = null
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/TestKit/TestKitDetail';
.disabled {
  /deep/ .el-upload--picture-card {
    display: none !important;
  }
}
</style>
